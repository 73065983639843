import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <Seo title="Privacy Policy | Clove" />

    <div className={"relative bg-white py-16"}>
      <div className={"mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl text-content"}>
        <h1 className="heading-primary">Privacy Policy</h1>

        <p>
          <strong>Last updated: June 26, 2020</strong>
        </p>

        <p>
          The privacy of your data — and it is your data, not ours! — is a big
          deal to us. In this policy, we lay out: what data we collect and why;
          how your data is handled; and your rights to your data. We promise we
          never sell your data: never have, never will.
        </p>
        <p>
          This policy applies to all products built and maintained by Clove,
          including Customer Hub.
        </p>

        <h3>What we collect and why</h3>
        <p>
          Our guiding principle is to collect only what we need. Here’s what
          that means in practice:
        </p>

        <h3>Identity & access</h3>
        <p>
          When you sign up for a Clove product, we typically ask for identifying
          information such as your name, email address, and maybe a company
          name. That’s just so you can personalize your new account, and we can
          send you invoices, updates, or other essential information. We
          sometimes also give you the option to add a profile picture that
          displays in our products, but we do not normally look at or access
          that picture. We’ll never sell your personal info to third parties,
          and we won’t use your name or company in marketing statements without
          your permission either.
        </p>

        <h3>Billing information</h3>
        <p>
          When you pay for a Clove product, we ask for your credit card and
          billing address. That's so we can charge you for service, calculate
          taxes due, and send you invoices. Your credit card is passed directly
          to our payment processor and doesn't ever go through our servers. We
          store a record of the payment transaction, including the last 4 digits
          of the credit card number and as-of billing address, for account
          history, invoicing, and billing support. We store your billing address
          to calculate any sales tax due in the United States, to detect
          fraudulent credit card transactions, and to print on your invoices.
        </p>

        <h3>Geolocation data</h3>
        <p>
          We log all access to all accounts by full IP address so that we can
          always verify no unauthorized access has happened. We keep this login
          data for as long as your product account is active.
        </p>
        <p>
          We also log full IP addresses used to sign up a product account. We
          keep this record forever because they are used to mitigate spammy
          signups.
        </p>
        <p>
          Web analytics data are also tied temporarily to IP addresses to assist
          with troubleshooting cases.{" "}
        </p>

        <h3>Website interactions</h3>
        <p>
          When you browse our marketing pages or applications, your browser
          automatically shares certain information such as which operating
          system and browser version you are using. We track that information,
          along with the pages you are visiting, page load timing, and which
          website referred you for statistical purposes like conversion rates
          and to test new designs. We sometimes track specific link clicks to
          help inform some design decisions. These web analytics data are tied
          to your IP address and user account if applicable and you are signed
          into our Services.
        </p>

        <h3>Cookies and Do Not Track</h3>
        <p>
          We do use persistent first-party cookies to store certain preferences,
          make it easier for you to use our applications, and support some
          in-house analytics. A cookie is a piece of text stored by your browser
          to help it remember your login information, site preferences, and
          more. You can adjust cookie retention settings in your own browser. To
          learn more about cookies, including how to view which cookies have
          been set and how to manage and delete them, please visit:
          www.allaboutcookies.org.
        </p>
        <p>
          At this time, our sites and applications do not respond to Do Not
          Track beacons sent by browser plugins.
        </p>

        <h3>Voluntary correspondence</h3>
        <p>
          When you write Clove with a question or to ask for help, we keep that
          correspondence, including the email address, so that we have a history
          of past correspondences to reference if you reach out in the future.
        </p>
        <p>
          We also store any information you volunteer, like surveys. Sometimes
          when we do customer interviews, we may ask for your permission to
          record the conversation for future reference or use. We only do so if
          you give your express consent.
        </p>

        <h3>Information we do not collect</h3>
        <p>
          We don’t collect any characteristics of protected classifications
          including age, race, gender, religion, sexual orientation, gender
          identity, gender expression, or physical and mental abilities or
          disabilities. You may provide these data voluntarily, such as if you
          include a pronoun preference in your email signature when writing into
          our Support team.
        </p>
        <p>
          We also do not collect any biometric data. You are given the option to
          add a picture to your user profile, which could be a real picture of
          you or a picture of something else that represents you best. We do not
          extract any information from profile pictures: they are for your use
          alone.
        </p>

        <h3>When we access or share your information</h3>
        <p>
          Our default practice is to not access your information. The only times
          we’ll ever access or share your info are:
        </p>
        <ul>
          <li>
            To provide products or services you've requested. We do use some
            third-party services to run our applications and only to the extent
            necessary process some or all of your personal information via these
            third parties.{" "}
          </li>
          <li>
            To help you troubleshoot or squash a software bug, with your
            permission. If at any point we need to access your account to help
            you with a Support case, we will ask for your consent before
            proceeding.
          </li>
          <li>
            To investigate, prevent, or take action regarding{" "}
            <a href="/restricted/">restricted uses</a>. Accessing a customer’s
            account when investigating potential abuse is a measure of last
            resort. We have an obligation to protect the privacy and safety of
            both our customers and the people reporting issues to us. We do our
            best to balance those responsibilities throughout the process. If we
            do discover you are using our products for a restricted purpose, we
            will report the incident to the appropriate authorities.
          </li>
          <li>
            When required under applicable law. If the appropriate law
            enforcement authorities have the necessary warrant, criminal
            subpoena, or court order requiring we share data, we have to comply.
            Otherwise, we flat-out reject requests from local and federal law
            enforcement when they seek data. And unless we’re legally prevented
            from it, we’ll always inform you when such requests are made. We
            have never received a National Security Letter or Foreign
            Intelligence Surveillance Act (FISA) order.
          </li>
        </ul>
        <p></p>
        <p>
          If Clove is acquired by or merged with another company — we don’t plan
          on that, but if it happens — we’ll notify you well before any info
          about you is transferred and becomes subject to a different privacy
          policy.
        </p>

        <h3>Location of Site and Data</h3>
        <p>
          Our products and other web properties are operated in the United
          States. If you are located in the European Union or elsewhere outside
          of the United States, please be aware that any information you provide
          to us will be transferred to the United States. By using our Site,
          participating in any of our services and/or providing us with your
          information, you consent to this transfer.
        </p>

        <h3>Your Rights With Respect to Your Information</h3>
        <p>
          At Clove, we apply the same data rights to all customers, regardless
          of their location. Currently some of the most privacy-forward
          regulations in place are the European Union’s General Data Protection
          Regulation (“GDPR”) and California Consumer Privacy Act (“CCPA”) in
          the US. Clove recognizes all of the rights granted in these
          regulations, except as limited by applicable law. These rights
          include:
        </p>
        <ul>
          <li>
            Right to Know. You have the right to know what personal information
            is collected, used, shared or sold. We outline both the categories
            and specific bits of data we collect, as well as how they are used,
            in this privacy policy.
          </li>
          <li>
            Right of Access. This includes your right to access the personal
            information we gather about you, and your right to obtain
            information about the sharing, storage, security and processing of
            that information.
          </li>
          <li>
            Right to Correction. You have the right to request correction of
            your personal information.
          </li>
          <li>
            Right to Erasure / “To be Forgotten”. This is your right to request,
            subject to certain limitations under applicable law, that your
            personal information be erased from our possession and, by
            extension, all of our service providers. Fulfillment of some data
            deletion requests may prevent you from using Clove services because
            our applications may then no longer work. In such cases, a data
            deletion request may result in closing your account.
          </li>
          <li>
            Right to Complain. You have the right to make a complaint regarding
            our handling of your personal information with the appropriate
            supervisory authority. To identify your specific authority or find
            out more about this right, EU individuals should go to
            https://edpb.europa.eu/about-edpb/board/members_en.
          </li>
          <li>
            Right to Restrict Processing. This is your right to request
            restriction of how and why your personal information is used or
            processed, including opting out of sale of personal information.
            (Again: we never have and never will sell your personal data).
          </li>
          <li>
            Right to Object. You have the right, in certain situations, to
            object to how or why your personal information is processed.
          </li>
          <li>
            Right to Portability. You have the right to receive the personal
            information we have about you and the right to transmit it to
            another party.
          </li>
          <li>
            Right to not be subject to Automated Decision-Making. You have the
            right to object and prevent any decision that could have a legal, or
            similarly significant, effect on you from being made solely based on
            automated processes. This right is limited, however, if the decision
            is necessary for performance of any contract between you and us, is
            allowed by applicable law, or is based on your explicit consent.
          </li>
          <li>
            Right to Non-Discrimination. This right stems from the CCPA. We do
            not and will not charge you a different amount to use our products,
            offer you different discounts, or give you a lower level of customer
            service because you have exercised your data privacy rights.
            However, the exercise of certain rights (such as the right “to be
            forgotten”) may, by virtue of your exercising those rights, prevent
            you from using our Services.
          </li>
        </ul>
        <p></p>
        <p>
          Many of these rights can be exercised by signing in and directly
          updating your account information.
        </p>
        <p>
          If you have questions about exercising these rights or need
          assistance, please contact us at hello@clovecx.com. For requests to
          delete personal information or know what personal information has been
          collected, we will first verify your identity using a combination of
          at least two pieces of information already collected including your
          user email address. If an authorized agent is corresponding on your
          behalf, we will first need written consent with a signature from the
          account holder before proceeding.
        </p>
        <p>
          If you are in the EU, you can identify your specific authority to file
          a complaint or find out more about GDPR, at
          https://edpb.europa.eu/about-edpb/board/members_en.
        </p>

        <h3>How we secure your data</h3>
        <p>
          All data is encrypted via SSL/TLS when transmitted from our servers to
          your browser. The database backups are also encrypted. Most data are
          not encrypted while they live in our database (since it needs to be
          ready to send to you when you need it), but we go to great lengths to
          secure your data at rest.
        </p>
        <p>
          For more information about how we keep your information secure, please
          review our <a href="/security/">security overview</a>.
        </p>

        <h3>When you delete data in your product accounts</h3>
        <p>
          In many of our applications, we give you the option to trash data.
          Anything you trash on your product accounts while they are active will
          be kept in an accessible trash can for up to 30 days (it varies a
          little by product). After that, the trashed data are no longer
          accessible via the application and are deleted from our active servers
          within the next 30 days. We also have some backups of our application
          databases, which are kept for up to another 30 days. In total, when
          you trash things in our applications, they are purged within 90 days
          from all of our systems and logs. Retrieving data for a single account
          from a backup is cost-prohibitive and unduly burdensome so if you
          change your mind you’ll need to do so before your data are deleted
          from our active servers.
        </p>
        <p>
          We also delete your data after an account is cancelled. In this case,
          there is no period of data being kept in an accessible trash can so
          your data are purged within 60 days. This applies both for cases when
          an account owner directly cancels and for auto-cancelled accounts.{" "}
        </p>

        <h3>Changes & questions</h3>
        <p>
          We may update this policy as needed to comply with relevant
          regulations and reflect any new practices.{" "}
        </p>
        <p>
          Have any questions, comments, or concerns about this privacy policy,
          your data, or your rights with respect to your information? Please get
          in touch by emailing us at hello@clovecx.com and we’ll be happy to
          answer them!
        </p>

        <p>
          Adapted from the{" "}
          <a href="https://github.com/basecamp/policies">
            Basecamp open-source policies
          </a>{" "}
          / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
